<template>
  <a-drawer width="75%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose" :maskClosable="false">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :model="form" :rules="rules">
<!--      <a-form-model-item label="备注" prop="remark" >-->
<!--        <a-input v-model="form.remark" placeholder="请输入备注" />-->
<!--      </a-form-model-item>-->
      <a-form-model-item label="Batch Name:" prop="name" >
        <a-input v-model="form.name" placeholder="" :maxLength="100" />
        <div style="display: flex;justify-content: flex-end">{{ ((form.name && form.name.length) || 0) }}/100</div>
      </a-form-model-item>
      <a-form-model-item label="Client:" prop="userId" >
<!--        <a-input v-model="form.userId" placeholder="Search Client" />-->
        <a-select
          placeholder="-Client-"
          style="width: 100%"
          showSearch
          v-model="form.userId"
          optionFilterProp="title"
          @change="changeClient"
        >
          <a-select-option v-for="item in clientList" :key="item.userId" :value="item.userId" :title="item.deptName">
            {{ item.deptName }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="Brand" prop="brandId" >
<!--        <a-input v-model="form.brandId" placeholder="" />-->
        <a-select
          placeholder="-Brand-"
          style="width: 100%"
          showSearch
          v-model="form.brandId"
          optionFilterProp="title"
          @change="changeBrand"
        >
          <a-select-option v-for="item in brandListData" :key="item.id" :value="item.id" :title="item.name">
            {{ item.name }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="Omni Product:" prop="skuId" >
<!--        <a-input v-model="form.skuId" placeholder="Search ik Product" />-->
        <a-select
          placeholder="-Omni Product-"
          style="width: 100%"
          showSearch
          v-model="form.skuId"
          optionFilterProp="title"
          @change="changeProduct"
        >
          <a-select-option v-for="item in productList" :key="item.id" :value="item.id" :title="item.name + '('+item.productModel +')'">
            {{ item.name }}({{item.productModel}})
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="Brand Product:" prop="brandProduct" >
<!--        <a-input v-model="form.brandProduct" placeholder="Search Brand Sku" />-->
        <a-select
          placeholder="-Brand Product-"
          style="width: 100%"
          showSearch
          v-model="form.brandProduct"
          optionFilterProp="title"
          @change="changeBrandProduct"
        >
          <a-select-option v-for="item in brandSkuList" :key="item.id" :value="item.id" :title="item.name">
            {{ item.name }}
          </a-select-option>
        </a-select>
      </a-form-model-item>

<!--      <a-form-model-item label="Factory:" prop="factoryId" >-->
<!--        <a-select-->
<!--          placeholder="-Factory-"-->
<!--          style="width: 100%"-->
<!--          showSearch-->
<!--          v-model="form.factoryId"-->
<!--          optionFilterProp="title"-->
<!--        >-->
<!--          <a-select-option v-for="item in factoryUserList" :key="item.id" :value="item.id" :title="item.nickname + '('+item.email+')'">-->
<!--            {{ item.nickname + '('+item.email+')' }}-->
<!--          </a-select-option>-->
<!--        </a-select>-->
<!--      </a-form-model-item>-->
      <a-form-model-item label="Flavor:" prop="flavorId" >
        <!--        <a-input v-model="form.brandProduct" placeholder="Search Brand Sku" />-->
        <a-select
          placeholder="-Flavor-"
          style="width: 100%"
          showSearch
          v-model="form.flavorId"
          optionFilterProp="title"
        >
          <a-select-option v-for="item in flavorList" :key="item.id" :value="item.id" :title="item.name">
            {{ item.name }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="Production Quantity:" prop="num" >
        <a-input-number :min="0" step="1" v-model="form.num" placeholder="" />
        <span style="margin-left: 5px">PCS</span>
      </a-form-model-item>
      <a-form-model-item label="Batch Popup Type:" prop="type" >
        <div style="display:flex;align-items: center">
          <a-select v-model="form.type">
            <a-select-option :key="0">Popup template</a-select-option>
            <a-select-option :key="1">URL</a-select-option>
          </a-select>
          <a-input v-model="form.popValue" placeholder="" style="margin-left: 5px;" />
        </div>



      </a-form-model-item>
<!--      <a-form-model-item label="弹出值(批次值大于app设置的值)" prop="popValue" >-->
<!--      </a-form-model-item>-->
      <a-form-model-item label="Batch Notes:" prop="notes" >
        <a-textarea
          v-model="form.notes"
          placeholder=""
          :maxLength="200"
          auto-size
        />
        <div style="display: flex;justify-content: flex-end">{{ ((form.notes && form.notes.length) || 0) }}/200</div>
      </a-form-model-item>

      <a-form-model-item label="Select Curves:" prop="curvesId" >
        <!--        <a-input v-model="form.brandProduct" placeholder="Search Brand Sku" />-->
        <a-select
          placeholder="-Curves-"
          style="width: 100%"
          showSearch
          v-model="form.curvesId"
          optionFilterProp="title"
        >
          <a-select-option v-for="item in skuCurvesDataList" :key="item.id" :value="item.id" :title="item.name">
            {{ item.name }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
<!--      <a-form-model-item label="Status:" prop="status" >-->
<!--        <a-select v-model="form.status">-->
<!--          <a-select-option :key="0">To be confirmed</a-select-option>-->
<!--          <a-select-option :key="1">Has been comfired</a-select-option>-->
<!--          <a-select-option :key="2">In production</a-select-option>-->
<!--          <a-select-option :key="3">Completed</a-select-option>-->
<!--        </a-select>-->
<!--      </a-form-model-item>-->

<!--      <a-form-model-item label="曲线多个id" prop="curvesId" >-->
<!--        <a-input v-model="form.curvesId" placeholder="请输入曲线多个id" />-->
<!--      </a-form-model-item>-->

      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            Save
          </a-button>
          <a-button type="dashed" @click="cancel">
            Cancel
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getBatch, addBatch, updateBatch } from '@/api/cigarette/batch'
import {listDept} from "@/api/system/dept";
import {listSku} from "@/api/cigarette/sku";
import {getProductList, listSku as goodsListSku} from "@/api/cigarette/goodSku";
import {listBrand} from "@/api/cigarette/brand";
import { listSkuCurves } from '@/api/cigarette/skuCurves'
import { listSkuFlavor } from '@/api/cigarette/skuFlavor'
import {allFactoryList} from '@/api/biz/user'
export default {
  name: 'CreateForm',
  props: {
  },
  components: {
  },
  data () {
    return {
      submitLoading: false,
      formTitle: '',
      // 表单参数
      form: {
        id: null,

        createTime: null,

        remark: null,

        name: null,

        userId: null,

        brandId: null,

        skuId: null,

        brandProduct: null,

        num: null,

        notes: null,

        status: 0,

        type: null,

        curvesId: null,

        popValue: null,
        flavorId: null,
        productModel: null,
        factoryId:null

      },
      // 1增加,2修改
      formType: 1,
      open: false,
      productList:[],
      rules: {
        name: [
          { required: true, message: 'Batch Name cannot be empty', trigger: 'blur' }
        ],
        userId: [{
          required: true, message: 'Client cannot be empty', trigger: 'change'
        }],
        brandId: [{
          required: true, message: 'Brand cannot be empty', trigger: 'change'
        }],
        brandProduct: [{
          required: true, message: 'Brand Product cannot be empty', trigger: 'change'
        }],
        factoryId: [{
          required: true, message: 'Factory cannot be empty', trigger: 'change'
        }],
        curvesId: [{
          required: true, message: 'Curves cannot be empty', trigger: 'change'
        }],
      },
      clientList: [],
      brandListData:[],
      brandSkuList:[],
      skuCurvesDataList : [],
      flavorList: [],
      factoryUserList: []
    }
  },
  filters: {
  },
  created () {
    this.getAllGoodsSkuList(null);
    this.getClientList();
    this.getBrandList(null);
    this.getBrandSkuList(null,null);
    this.getUserListData();
  },
  computed: {
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    getAllGoodsSkuList(brandId) {
      getProductList({
        status:1,
        brandId:brandId
      }).then(res => {
        this.productList = res.data;
      })
    },
    getUserListData() {
      allFactoryList().then(res => {
        this.factoryUserList = res.data;
      })
    },
    changeBrandProduct(e) {
     let brandSkuData= this.brandSkuList.filter(i => i.id == e);
     console.log(brandSkuData)
     if(brandSkuData.length > 0){
       this.form.productModel = brandSkuData[0].productModel;
     }
      this.getBrandSkuCurvesList(e);
      this.getFlavorListData(e);
    },
    changeProduct(e) {
      this.getBrandSkuList(this.form.brandId,e)
    },
    getFlavorListData(skuId) {
      listSkuFlavor({
        status:1,
        skuId:skuId
      }).then(res => {
       this.flavorList = res.data;
      })
    },
    getBrandSkuCurvesList(skuId) {
      listSkuCurves({
        skuId:skuId,
        status:1
      }).then(res => {
        this.skuCurvesDataList = res.data;
      })
    },
    changeClient(e) {
      this.getBrandList(e);
      this.form.brandId = null;
    },
    getClientList() {
      listDept({
        status: 1,
        isCreate:1
      }).then(res => {
        this.clientList =res.data;
      })
    },
    getBrandList(userId) {
      listBrand({
        status:1,
        brandStatus:1,
        userId:userId
      }).then(res => {
        this.brandListData = res.data;
      })
    },
    getBrandSkuList(brandId,skuId) {
      listSku({
        status:1,
        brandId:brandId,
        skuId:skuId
      }).then(res => {
        this.brandSkuList = res.data;
      })
    },
    changeBrand(e) {
      this.getAllGoodsSkuList(e);
      this.getBrandSkuList(e,this.form.skuId);
    },
    onClose () {
      this.open = false
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.form = {
        id: null,
        createTime: null,
        remark: null,
        name: null,
        userId: null,
        brandId: null,
        skuId: null,
        brandProduct: null,
        num: null,
        notes: null,
        status: 0,
        type: null,
        curvesId: null,
        popValue: null,
        flavorId: null,
        productModel: null,
        factoryId : null
      }
      this.$nextTick(() => {
        if(this.$refs.form){
          this.$refs.form.resetFields()
        }
      })
    },
    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = 'Create Production Batch'
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getBatch({"id":id}).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = 'Modify Production Batch'
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          if (this.form.id !== undefined && this.form.id !== null) {
            updateBatch(this.form).then(response => {
              this.$message.success(
                'success',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            addBatch(this.form).then(response => {
              this.$message.success(
                'success',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>

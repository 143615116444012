import request from '@/utils/request'


// 查询新-产品规格列表
export function listSku(query) {
  return request({
    url: '/cigarette/goods-sku/list',
    method: 'get',
    params: query
  })
}

export function getProductList(query) {
  return request({
    url:'/cigarette/goods-sku/getProductList',
    method: 'get',
    params: query
  })
}

export function brandAdminListSku(query) {
  return request({
    url: '/cigarette/goods-sku/brandAdminList',
    method: 'get',
    params: query
  })
}
// 查询新-产品规格分页
export function pageSku(query) {
  return request({
    url: '/cigarette/goods-sku/page',
    method: 'get',
    params: query
  })
}

// 查询新-产品规格分页
export function newPageSku(query) {
  return request({
    // url: '/cigarette/goods-sku/newPage',
    url: '/cigarette/goods/page',
    method: 'get',
    params: query
  })
}

// 查询新-产品规格详细
export function getSku(data) {
  return request({
    url: '/cigarette/goods-sku/detail',
    method: 'get',
    params: data
  })
}

// 新增新-产品规格
export function addSku(data) {
  return request({
    url: '/cigarette/goods-sku/add',
    method: 'post',
    data: data
  })
}

// 修改新-产品规格
export function updateSku(data) {
  return request({
    url: '/cigarette/goods-sku/edit',
    method: 'post',
    data: data
  })
}

// 删除新-产品规格
export function delSku(data) {
  return request({
    url: '/cigarette/goods-sku/delete',
    method: 'post',
    data: data
  })
}
